@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;1,300&family=Noto+Sans:wght@300&display=swap');

body{
  position: relative; 
  font-family: 'Noto Sans', sans-serif;
}

.navHome, #main{    
  background: linear-gradient(rgba(37, 5, 49, 0.7), rgba(60, 14, 78, 0.7)), url(assets/mainbg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;    
}
.footerClass ul li, p{
  font-weight: normal;
}
.navOther{
  background: #191a2b;
}

.aboutClass{
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent), url(assets/aboutbg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  -webkit-filter: grayscale(80%); /* Safari 6.0 - 9.0 */
  filter: grayscale(80%);
}

.servicesClass{
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent), url(assets/servicesbg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.domainCard ul li{
  padding: 12px 0 6px 0;
  border-bottom: 1px solid silver;
}
.domainCard h3{
  padding: 2px 0 15px 0;
  font-size: 1.4rem;
  font-weight: 600;
}
.domainCard button{
  margin: 30px 0 2px 0;
  padding: 10px;
  font-weight: 700;
  color: white;
  border-radius: 5px;
  background-color: #2563eb;
}

.careerClass{
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent), url(assets/careerBG.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  -webkit-filter: grayscale(60%); /* Safari 6.0 - 9.0 */
  filter: grayscale(60%);
}
.careerStack{
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent), url(assets/careerStackBG.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;  
}
/* .contactClass{
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent), url(assets/contact.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;  
} */
.talkBubbbleOne {
  width: 320px;
  height: 80px;
  background: red;
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.talkBubbbleOne:before {
  content: "";
  position: absolute;
  right: 100%;
  top: 95px;
  left: 55px;
  width: 0;
  height: 0;
  border-top: 45px solid red;
  border-right: 25px solid transparent;
  border-left: 16px solid transparent;  
}

blockquote{
  font-size: 1.4em;
  width:100%;  
  margin:0;
  font-family:Open Sans;
  font-style:italic;
  color: #555555;
  padding:2px;  
  line-height:1.6;
  position: relative;    
}

blockquote::before{
  font-family:Arial;
  content: "\201C";
  color:#fff;
  font-size:4em;
  position: absolute;
  left: -15px;
  top:-75px;
}

/* blockquote::after{  
  font-family:Arial;
  content: "\201D";
  color:#fff;
  font-size:4em;
  position: absolute;
  left: 425px;
  top:185px;
} */

blockquote span{
  display:block;
  color:#efefef;
  font-style: normal;
  font-weight: bold;
  margin-top:1em;
  position: absolute;
  text-align:end;  
  width: 100%;  
}

@media (max-width: 639px){
 
}
/* ============ flip hover styles ============ */

/* .mainContainer{
  position: relative;
  width: 250px;
  height: 320px;
}
.card{
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 3s ease;
}
.card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: yellow;
  color: #333;  
}
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: blue;
  color: #333;
  transform: rotateY(180deg);
}
.card:hover {
  transform: rotateY(180deg);
} */


 /* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
 .flip-card {
  background-color: transparent;
  width: 300px;
  height: 200px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #fff;  
  color: #2c0f37;
  
}

/* Style the back side */
.flip-card-back {
  background-color: #06283d;
  color: #fff;
  transform: rotateY(180deg);
} 